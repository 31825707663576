import React from 'react';
import Loadable from 'react-loadable';
import PublicProvider from '@/components/providers/PublicProvider';
import { LoadingPage } from '@/components/loading/loadingBase';
import HeaderMetadata from '@/layouts/HeaderMetadata';

const RegisterLoadable = Loadable({
  loader: () => import('@/sections/Register'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});
const RegisterPage: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderMetadata title="Register" />
      <PublicProvider>
        <RegisterLoadable />
      </PublicProvider>
    </React.Fragment>
  );
};

export default RegisterPage;
