import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import SettingsService from '@/services/settings';
// import UserAction from '@/reducers/user/action';
import { StorageKeys } from '@/utils/enum';
import { RootState } from '@/reducers/model';
import UserService from '@/services/user';
import AuthAction from '@/reducers/auth/action';
import { checkingProfileAvailable, forceLogout } from '@/utils/auth';
import RoutePaths from '@/utils/routes';
import { navigate } from 'gatsby';
import { LoadingPage } from '../loading/loadingBase';
import CreditService from '@/services/credit';
import HomeLimitAction from '@/reducers/home-credit/action';
// import useGetConstantValueQuery from '@/queries/settings/useGetConstantValue';

const PublicProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const myProfile = useSelector((state: RootState) => state.auth.myProfile);
  // const { refetch: getConstantValue, data } = useGetConstantValueQuery({
  //   enabled: false,
  // });

  const [isLoading, setIsLoading] = useState(true);
  const getUserProfile = async () => {
    try {
      const profile = await UserService.getMyProfile();
      dispatch({
        type: AuthAction.RUN_AUTHENTICATED_FLOW,
        payload: profile,
      });
    } catch (error) {
      forceLogout();
    } finally {
      setIsLoading(false);
    }
  };
  const checkUserAssets = async () => {
    if (
      (typeof window !== 'undefined' && window.location.pathname.includes(RoutePaths.REGISTER)) ||
      window.location.pathname.includes(RoutePaths.SETTINGS) ||
      window.location.pathname.includes(RoutePaths.AGENT_LOOKUP)
    ) {
      try {
        const mySettingHomeCredit = await CreditService.subSetting(false);

        // dispatch({
        //   type: UserAction.USER_ASSETS,
        //   payload: assets.data,
        // });
        dispatch({
          type: HomeLimitAction.SET_MY_SETTING_HOME_CREDIT_LIST,
          payload: (mySettingHomeCredit as any)?.results,
        });
      } catch (error) {
        // Do nothing
      }
    }
  };

  const checkAccountPermission = () => {
    const token = localStorage.getItem(StorageKeys.SESSION_KEY);
    if (!token) {
      setIsLoading(false);
    } else if (!myProfile) {
      getUserProfile();
    } else {
      setIsLoading(false);
      checkingProfileAvailable(myProfile);
    }
  };
  const checkDenyAccess = () => {
    const token = localStorage.getItem(StorageKeys.SESSION_KEY);
    const pathname = window.location.pathname;
    if ((pathname.includes(RoutePaths.REGISTER) || pathname.includes(RoutePaths.LOGIN)) && token) {
      navigate(RoutePaths.DASHBOARD);
    }
  };

  React.useEffect(() => {
    checkUserAssets();
    checkAccountPermission();
    checkDenyAccess();
  }, []);

  return <React.Fragment>{!isLoading ? children : <LoadingPage isLoading={true} />}</React.Fragment>;
};

export default PublicProvider;
